<template>
  <TradeProcedureSidebar
    :title="title"
    tabs="securityTrade"
  >
    <template v-slot:header>
      <div class="admin__button">
        <VToggleDisplay
          v-if="isToggleDisplay"
          :class="['mr-20', classToggleDisplay]"
        />
      </div>
    </template>
  </TradeProcedureSidebar>
</template>

<script>
import TradeProcedureSidebar from '@/components/views/TradeProcedure/VTradeProcedureSidebar'
import VToggleDisplay from '@/components/ui/buttons/VToggleDisplay'

export default {
  components: {
    TradeProcedureSidebar,
    VToggleDisplay
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isToggleDisplay: {
      type: Boolean,
      required: false,
      default: true
    },
    classToggleDisplay: {
      type: String,
      required: false
    }
  },
}
</script>

<style>
.admin__button {
  display: flex;
}
</style>
